<template>
    <div class="reports-detail full-width" v-if="translates && translates[langUrl]">
        <div class="reports-detail__photo-container" :style="`background-image: url(${ imageSrc(item.picture) })` ">
            <div class="reports-detail__title"> {{ item.title }} </div>
            <div class="reports-detail__preview">{{ item.preview }}</div>
            <div class="reports-detail__photo-container__opacity"></div>
        </div>
        <div class="reports-detail__main-container">
            <div class="reports-detail__main-container__row">
                <div class="reports-detail__reports-info">
                    <div class="reports-detail__date">{{ postTime(item.createdAt) }}</div>
                    <!-- <template > -->
                        <div v-for="(content, index) in item.contents" :key="index" class="reports-detail__content">
                            <div
                                v-if="content.type === NEWS_CONTENT_TYPE.TEXT"
                                class="reports-detail__text"
                                v-html="content.value"></div>
                            <!-- <div
                                v-if="content.type === NEWS_CONTENT_TYPE.PICTURE"
                                class="reports-detail__text reports-detail__picture">
                                <img :src="imageSrc(content.value)" />
                            </div> -->
                            <div
                                v-if="content.type === NEWS_CONTENT_TYPE.YOUTUBE"
                                class="reports-detail__text">
                                <youtube
                                    class="reports-detail__youtube"
                                    :video-id="youtubeVideoId(content.value)" />
                            </div>
                        </div>
                    <!-- </template> -->
                    <div class="reports-detail__picture-block">
                        <template v-for="(content, index) in item.contents">
                            <div :key="index"
                                v-if="content.type === NEWS_CONTENT_TYPE.PICTURE"
                                class="reports-detail__picture-block__picture__container"
                                @click="openPhoto(index)"
                            >
                                <img class="reports-detail__picture-block__picture" :src="imageSrc(content.value)" />
                                
                            </div>
                        </template>
                    </div>
                </div>
                <ReportsPreview
                    v-if="isDesktop"
                    class="column"
                    :title="translates[langUrl].otherReports[lang]"
                    :need-news="2"
                />
            </div>
            <div class="reports-detail__main-container__social">
                <div class="reports-detail__main-container__social__share">{{ translates[langUrl].detailPage_share[lang] }}</div>
                <div class="reports-detail__main-container__social__item facebook"></div>
                <div class="reports-detail__main-container__social__item VK"></div>
                <div class="reports-detail__main-container__social__item telegram"></div>
                <div class="reports-detail__main-container__social__item instagram"></div>
            </div>
            <!-- <ReportsPreview
                v-if="!isDesktop"
                class="column"
                title="Другие новости"
                :need-news="2"
            /> -->
        </div>
        <v-dialog
            max-width="1500px"
            v-model="shownPhoto"
        >
            <div class="reports-detail__picture-block__popup" :style="`background-image: url(${ imageSrc(item.contents[activePhotoIndex].value) })`">
                <div class="reports-detail__picture-block__popup__overlay"></div>
                <div class="reports-detail__picture-block__popup__photo"
                    :style="`background-image: url(${ imageSrc(item.contents[activePhotoIndex].value) })`">
                    <div class="reports-detail__picture-block__close" @click="shownPhoto = false"></div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import ReportsPreview from '../../components/common/ReportsPreview.vue'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import MobilePreview from '../../components/common/trip/PreviewCard.vue'
    import BaseButton from '../../components/common/BaseButton.vue'

    import store from '@/store';
    import { isMobile, SplideSlideCounter } from '@/helpers';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE, NEWS_CONTENT_TYPE } from '@/vars';
    import moment from '@/plugins/moment';

    export default {
        name: 'ReportsDetail',
        metaInfo () {
            return {
                title: this.item.title,
                meta: [{
                    vmid: 'description',
                    name: 'description',
                    content: this.item.preview,
                }],
            }
        },
        components: {
            ReportsPreview,
            Splide,
            SplideSlide,
            MobilePreview,
            BaseButton
        },
        data: () => ({
            TRIP_STATUS,
            NEWS_CONTENT_TYPE,
            imageSrc,
            isDesktop: false,
            title: undefined,
            shownPhoto: false,
            activePhotoIndex: 0,
            tourSplideCounter: 1,
            langUrl: 'Reports'
        }),
        computed: {
            ...mapState('reports', {
                reports: state => state.entities,
                item: state => state.entity
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('auth', ['user']),
            options () {
                return {
                    offset: 100
                }
            }
        },
        // async beforeRouteEnter (to, from, next) {
        //     if (to.params.code) {
        //         await store.dispatch('reports/getByCode', { code: to.params.code });
        //     }
        //     next();
        // },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if (this.$route.params.code) {
                await store.dispatch('reports/getByCode', { code: this.$route.params.code });
                this.title = this.item.title;
                await this.getTrips();
            }
            this.$root.$emit('preloaderHide');
        },
        async updated() {
            if (this.item.code !== this.$route.params.code) {
                await store.dispatch('reports/getByCode', { code: this.$route.params.code });
                await this.getTrips();
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.tourSplideCounter = SplideSlideCounter(350, 1400);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: { 
            async getReports() {
                await store.dispatch('reports/fetch', {});
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            postTime(date) {
                return moment(date).calendar()
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            youtubeVideoId(link) {
                return link ? link.substring(link.indexOf('=') + 1) : '';
            },
            price (value) {
                return new Intl.NumberFormat('ru-RU').format(value);
            },
            async reportsList() {
                await this.$router.push({ name: 'reports-list' });
            },
            async tripList() {
                await this.$router.push({ name: 'trip-list' });
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            openPhoto(index) {
                this.activePhotoIndex = index
                console.log(this.activePhotoIndex)
                this.shownPhoto = true
            },
        }
    }
</script>

<style lang="scss">
.reports-detail {
    width: 100%;
    background: #FFFFFF;
	overflow: hidden;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    padding-bottom: 70px;
    &__photo-container {
        position: relative;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 30vw;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media all and (max-width: 768px) {
            height: 410px;
            width: 100%;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #021922;
            opacity: .7;
        }
    }
    &__title {
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 56px;
        line-height: 132%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        max-width: 1080px;
        z-index: 10;
        @media all and (max-width: 768px) {
            font-weight: 800;
            font-size: 24px;
            line-height: 150%;
        }
    }
    &__preview {
        max-width: 950px;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        z-index: 10;
        @media all and (max-width: 768px) {
            font-size: 16px;
            line-height: 150%;
            margin: 0 20px;
        }
    }
    &__date {
        margin-top: 45px;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #93A3B4;
        margin-bottom: 5px;
        @media all and (max-width: 768px) {
            margin-top: 24px;
        }
    }
    &__text {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #273155;
        img {
            height: 100%;
            max-height: 600px;
            border-radius: 12px;
        }
    }
    &__picture {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }
    &__youtube {
        width: 100%;
        height: 40vw;
        border-radius: 12px;
        overflow: hidden;
    }
    &__reports-info {
        width: 100%;
        max-width: 950px;
        @media all and(max-width: 1430px) {
          padding: 32px;  
        }
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        @media all and (max-width: 768px) {
            margin: 0 20px;
        }
        @media all and (min-width: 1280px) {
            margin: auto;
            max-width: 1400px;
        }
        &__row {
            display: flex;
            flex-direction: row;
            align-content: space-between;
            @media all and (max-width: 768px) {
            flex-direction: column;
            align-content: center;
        }
        }
        &__social {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            margin-bottom: 70px;
            &__share {
                margin-right: 10px;
            }
            &__item {
                width: 32px;
                height: 32px;
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid #93A3B4;
                border-radius: 50%;
                box-sizing: border-box;
                margin-right: 12px;
                cursor: pointer;
                &:last-of-type {
                    margin-right: none;
                }
                &.facebook {
                    background-image: url(../../assets/Facebook.svg);
                }
                &.VK{
                    background-image: url(../../assets/VK.svg);
                }
                &.telegram {
                    background-image: url(../../assets/Telegram.svg);
                    background-position: 6px 8px;
                }
                &.instagram {
                    background-image: url(../../assets/Instagram.svg);
                }
            }
        }
    }
    &__picture-block {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        &__picture {
            height: 100%;
            border-radius: 10px;
            &__container {
                width: 100%;
                height: 50vh;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
            }
        }
        &__popup {
            position: relative;
            width: 1500px;
            height: max-content;
            // background: #fff;
            background-size: cover;
            display: flex;
            flex-direction: column;
            border-radius: 30px;
            overflow: hidden;
            &__overlay {
                background: #fff;
                position: fixed;
                width: 1500px;
                height: 80vh;
                z-index: 10;
                opacity: 0.3;
                border-radius: 30px;
            }
            &__photo {
                position: relative;
                // width: 95vw;
                height: 80vh;
                background-position: center;
                background-size: contain;
                z-index: 20;
            }
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background-image: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
            z-index: 20;
        }
    }
}
</style>